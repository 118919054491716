import React, { createContext, useState } from 'react';

export const Context = createContext();

export const ContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [products, setProducts] = useState(null);

    return (
        <Context.Provider value={{ products, setProducts, user, setUser }}>
            {children}
        </Context.Provider>
    );
};
