import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Context } from '../Context';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase-config';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface Stats {
    clicks: number;
    uniqueClicks: number;
    dailyClicks: number[];
}

interface StatCardProps {
    title: string;
    value: number;
}

interface Product {
    brand: string;
    lang: string;
    longUrl: string;
    shortUrl: string;
    orderNumber: number;
    price: number;
    title: string;
    id: string;
}

const API_TOKEN = process.env.REACT_APP_TLY_API_TOKEN;
const GOOGLE_API_TOKEN = process.env.REACT_APP_GOOGLE_API_TOKEN;

const ProductPage: React.FC = () => {
    const navigate = useNavigate();
    const context = useContext(Context);
    const { id = "" } = useParams();

    const mapRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [product, setProduct] = useState<Product>();
    const [name, setName] = useState('');
    const [link, setLink] = useState('');
    const [longUrl, setLongUrl] = useState('');
    const [stats, setStats] = useState<Stats>({ clicks: 0, uniqueClicks: 0, dailyClicks: [] });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [view, setView] = useState('stats');

    const chartData = {
        labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
        datasets: [{
            label: 'Clicks by Week',
            data: stats.dailyClicks.slice(0, 4),
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        }],
    };

    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true,
                suggestedMax: 100,
            },
        },
    };

    useEffect(() => {
        if (!id || !context?.products) return;
        const product = context?.products?.find((p: any) => p.id === id);
        setProduct(product);
    }, [id, context?.products]);

    useEffect(() => {
        async function fetchGoogleMaps() {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_TOKEN}&libraries=places&callback=initMap`;
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }
        if (view === 'map') {
            fetchGoogleMaps();
        }
    }, [view]);

    useEffect(() => {
        const initMap = () => {
            if (!inputRef.current) {
                console.error('Input not found');
                return;
            };
            if (!mapRef.current) {
                console.error('Map not found');
                return;
            };

    
            const map = new google.maps.Map(mapRef.current, {
                center: { lat: -33.8688, lng: 151.2195 },
                zoom: 13,
                mapTypeControl: false,
            });
    
            const input = inputRef.current;
            const autocomplete = new google.maps.places.Autocomplete(input, {
                fields: ['place_id', 'geometry', 'formatted_address', 'name'],
            });
    
            autocomplete.bindTo('bounds', map);
            map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
    
            const marker = new google.maps.Marker({ map });
    
            marker.addListener('click', () => {
                const position = marker.getPosition();
                if (position) {
                    map.setCenter(position);
                    map.setZoom(17);
                }
            });
    
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
    
                if (!place.geometry || !place.geometry.location) {
                    setLongUrl(`No details available for input: ${place.name}`);
                    return;
                }
    
                map.setCenter(place.geometry.location);
                map.setZoom(17);
                marker.setPosition(place.geometry.location);
                marker.setVisible(true);
    
                setLongUrl(`https://search.google.com/local/writereview?placeid=${place.place_id}`);
            });
        };
        if (view === 'map') {
            window.initMap = initMap;
        }
    }, [view]);

    useEffect(() => {
        const fetchStats = async (link: string) => {
            if (!link) {
                setError('Please enter a valid link');
                return;
            }
            setIsLoading(true);
            setError('');
            try {
                const url = new URL("https://t.ly/api/v1/link/stats");
                url.searchParams.append("short_url", link);
                const response = await fetch(url.toString(), {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${API_TOKEN}`,
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                    },
                });

                if (!response.ok) throw new Error('Failed to fetch stats');
                const data = await response.json();
                const dailyClicks = data.daily_clicks || [];
                const clicks = data.clicks || 0;
                const uniqueClicks = data.unique_clicks || 0;
                setStats({ clicks, uniqueClicks, dailyClicks });
            } catch (error) {
                console.error('Error fetching stats:', error);
                setError('Failed to fetch stats. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };
        if (product) {
            setName(product?.title);
            setLink(product?.shortUrl);
            setLongUrl(product?.longUrl);
            fetchStats(product?.shortUrl);
        }
    }, [product]);

    const handleUpdate = async () => {
        try {
            const url = new URL("https://t.ly/api/v1/link");
            const headers = {
                "Authorization": `Bearer ${API_TOKEN}`,
                "Content-Type": "application/json",
                "Accept": "application/json",
            };
            const body = {
                "short_url": link,
                "long_url": longUrl,
            };

            const response = await fetch(url.toString(), {
                method: "PUT",
                headers,
                body: JSON.stringify(body),
            });

            if (!response.ok) throw new Error('Failed to update long_url');
            await updateProduct();
        } catch (error) {
            console.error('Error updating long_url:', error);
            setError('Failed to update long_url. Please try again later.');
        }
    };

    async function updateProduct() {
        try {
            if (!context?.products || !context?.user?.email) {
                console.error("No products or user email found.");
                return;
            }
            const newProducts = context.products.map((p: any) => {
                return p.id === id ? { ...p, title: name, longUrl: longUrl } : p;
            });
            const userRef = doc(db, `users/${context.user.email}`);
            await updateDoc(userRef, {
                products: newProducts
            });
            if (context.setProducts) {
                context.setProducts(newProducts);
            }
            alert('Product updated successfully!');
            navigate(-1);
        } catch (err) {
            console.error("Error updating product:", err);
            alert('Failed to update product.');
        }
    }

    return (
        <div className='w-full px-[8vw] py-10'>
            <div className='flex items-center mb-8'>
                <button className='bg-[#48b181] duration-300 transition-all hover:bg-[#19542c] px-4 py-2 text-white rounded-[10px]' onClick={() => navigate('/home')}>Go Back</button>
            </div>
            <div className='flex justify-center w-full my-10'>
                <h1 className='text-[1.5rem] font-bold'>{product?.title} - {product?.lang}</h1>
            </div>
            <div className='flex flex-col justify-center gap-4 mb-6 oito:gap-8 oito:flex-row'>
                <StatCard title="Total Scans" value={stats.clicks} />
                <StatCard title="Unique Scans" value={stats.uniqueClicks} />
                <StatCard title="Daily Scans" value={stats.dailyClicks.length} />
            </div>
            <div className="flex flex-col gap-4 mb-4">
                <p>Disc Name:</p>
                <div className="flex flex-col items-center w-full h-full gap-4 quatro:flex-row">
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="flex-1 bg-[transparent] w-full"
                    />
                    <button onClick={updateProduct} className="w-[7rem] h-full px-4 py-2 font-bold text-white bg-[#48b181] rounded-[10px] hover:bg-[#19542c]">
                        Save
                    </button>
                </div>
            </div>
            <div className="flex flex-col gap-4 mb-4">
                <p>Review Link:</p>
                <div className="flex flex-col items-center w-full h-full gap-4 quatro:flex-row">
                    <input
                        type="text"
                        value={longUrl}
                        onChange={(e) => setLongUrl(e.target.value)}
                        className="flex-1 bg-[transparent] w-full"
                    />
                    <button onClick={handleUpdate} className="w-[7rem] h-full px-4 py-2 font-bold text-white bg-[#48b181] rounded-[10px] hover:bg-[#19542c]">
                        Save
                    </button>
                </div>
            </div>
            {
                product?.brand === 'google' &&
                <div className='flex flex-col items-center justify-center w-full gap-4 mb-8'>
                    <p>View</p>
                    <div className='relative w-[18rem] h-8 border-[1px] border-black rounded-full overflow-hidden flex items-center'>
                        <div className={`bg-[var(--primary)] w-1/2 top-0 absolute left-0 h-full rounded-full ${view === 'stats' ? 'left-0' : 'left-1/2'} transition-all duration-200`}></div>
                        <button onClick={() => setView('stats')} className='relative z-[2] w-1/2'>Stats</button>
                        <button onClick={() => setView('map')} className='relative z-[2] w-1/2'>Find Google Link</button>
                    </div>
                </div>
            }
            {
                view === 'map' ?
                    <>
                        {
                            !isLoading && product?.brand === 'google' &&
                            <div>
                                <p>Enter your location to get the review link</p>
                                <input ref={inputRef} className="flex-1 w-full" type="text" placeholder="Enter a location" style={{ width: '90%', margin: '10px auto', padding: '10px', fontSize: '16px', boxSizing: 'border-box' }} />
                                <div ref={mapRef} style={{ height: '400px', width: '100%' }}></div>
                            </div>
                        }
                    </>
                    :
                    <div>
                        {error && <p className="text-red-500">{error}</p>}
                        {!isLoading && <Bar data={chartData} options={chartOptions} />}
                    </div>
            }
        </div>
    );
};

function StatCard({ title, value }: StatCardProps) {
    return (
        <div className='p-4 text-center bg-blue-100 rounded-lg shadow min-w-[12rem]'>
            <p className='text-lg font-semibold'>{title || 'Erro'}</p>
            <p className='text-xl'>{value || '0'}</p>
        </div>
    );
}

export default ProductPage;

