import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {

    const navigate = useNavigate();
    
    return (
        <div className='flex flex-col items-center justify-center w-full h-screen gap-4'>
            <h1 className='text-[1.5rem] font-bold'>Page not found</h1>
            <button onClick={() => navigate(-1)} className='px-6 py-1 border-2 border-black rounded-full'>Go back</button>
        </div>
    );
}

