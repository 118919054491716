import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth'; // If using react-firebase-hooks
import { auth } from '../firebase-config'; // Adjust the import path as necessary
import { sendPasswordResetEmail, signOut } from 'firebase/auth';
import Logo from '../content/feed-logo.png';

const Settings: React.FC = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth); // Get the currently signed-in user

  async function logout() {
    try {
      await signOut(auth);
      localStorage.clear();
      navigate('/');
    } catch (error) {
      alert("Log out failed.");
    }
  }

  async function resetPassword() {
    try {
      if (user?.email) {
        await sendPasswordResetEmail(auth, user.email);
        alert('Check your email for a password reset link.');
      }
    } catch (error) {
      alert('Failed to send password reset email.');
    }
  }

  return (
    <div className='flex flex-col items-center justify-center min-h-screen px-6 bg-white'>
      <img src={Logo} alt="Logo" id="logo" style={{ width: '250px', height: 'auto', marginBottom: '50px' }} className="mt-3 mb-4" /> {/* Logo at the top */}
      <h2 className="mb-6 text-2xl font-semibold text-gray-900">Account Info</h2>
      <div className='flex flex-col w-full max-w-xs gap-4'>
        <div className='flex flex-col gap-4'>
          <label htmlFor="email" className="block mb-2 text-sm font-bold text-gray-700">Email:</label>
          <input
            type="email"
            id="email"
            className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            value={user?.email || ''}
            disabled 
          />
          <button className="px-4 py-2 mb-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline" onClick={resetPassword}>
            Reset Password
          </button>
        </div>
      </div>
      <div className="flex items-center justify-between w-full max-w-xs mt-4"> 
        <button type="button" className="bg-[#48b181] duration-300 transition-all hover:bg-[#19542c] px-4 py-2 text-white rounded-[10px]" onClick={() => navigate('/home')}>
          Back to Home 
        </button>
        <button className='bg-[#48b181] duration-300 transition-all hover:bg-[#19542c] px-4 py-2 text-white rounded-[10px]' onClick={logout}>
          Log out
        </button>
      </div>
    </div>
  );
};

export default Settings;
