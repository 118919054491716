import React, { useEffect, useState } from 'react';
import Logo from '../content/feed-logo.png';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase-config'; // Make sure this path is correct
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';

export default function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        if (auth.currentUser)
            navigate('/home');
    }, [navigate]);

    function submit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault(); // Prevent the form from refreshing the page
        if (!email || !password) {
            alert('Please fill in all fields.');
        } else {
            signInWithEmailAndPassword(auth, email, password)
                .then(() => {
                    navigate('/home');
                })
                .catch((error) => {
                    console.error('Error signing in:', error);
                    alert('Invalid email or password. Please try again.');
                });
        }
    }

    function resetPassword() {
        if (!email) {
            alert('Please enter your email address to reset your password.');
        } else {
            sendPasswordResetEmail(auth, email)
                .then(() => {
                    alert('Password reset email sent!');
                })
                .catch((error) => {
                    const errorMessage = error.message;
                    alert(errorMessage);
                });
        }
    }

    return (
        <div className='flex items-center justify-center w-full h-screen'>
            <div className="w-[20rem] flex flex-col items-center bg-white rounded-[20px] p-8 gap-4">
                <div className="w-[10rem]">
                    <img src={Logo} alt="Logo" id="logo" />
                </div>
                <h2 className='font-bold'>Login</h2>
                <form onSubmit={submit} className='w-full'>
                    <div className="w-full">
                        <label htmlFor="email">Email</label>
                        <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" id="email" name="email" className='w-full' required />
                    </div>
                    <div className="w-full">
                        <label htmlFor="password">Password</label>
                        <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" id="password" className='w-full' name="password" required />
                    </div>
                    <div className='flex items-center justify-between mt-2'>
                        <button type="submit" className='bg-[#48b181] duration-300 transition-all hover:bg-[#2a5925] px-4 py-2 rounded-[10px] text-white'>Login</button>
                        <button onClick={resetPassword} className="text-sm text-green-600 hover:underline">Forgot password?</button>
                    </div>
                </form>
            </div>
        </div>
    );
}
