// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Import Firebase Authentication
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCI62v_t4dBwjE5WYU7ugUM8ChNgTmTrjw",
  authDomain: "feedbacko-58694.firebaseapp.com",
  projectId: "feedbacko-58694",
  storageBucket: "feedbacko-58694.appspot.com",
  messagingSenderId: "16485585008",
  appId: "1:16485585008:web:b09b40e731a64f76c824b5",
  measurementId: "G-4X670XDHFK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and export it
export const auth = getAuth(app);
export const db = getFirestore(app);
