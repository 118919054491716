import React, { useContext, useEffect, useState } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NotFoundPage from './pages/NotFound';
import Login from './pages/Login';
import Home from './pages/Home';
import Settings from './pages/Settings';
import ProductPage from './pages/ProductPage';
import { auth, db } from './firebase-config';
import { signOut } from 'firebase/auth';
import { Context } from './Context';
import { collection, documentId, getDocs, query, where } from 'firebase/firestore';

function App() {
	const context = useContext(Context);
	const [isLogged, setIsLogged] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async user => {
			setIsLogged(!!user);
			if (user) {
				const userInfo = await fetchUserInfo();
				if (!userInfo) {
					await signOut(auth);
					localStorage.clear();
					alert('Usuário não encontrado.');
					window.location.href = '/';
					return;
				}
				context?.setUser(userInfo);
				context?.setProducts(userInfo?.products || []);
				setIsLoading(false);
			} else {
				setIsLoading(false);
				setIsLogged(false);
			}
		});
		return () => unsubscribe();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const fetchUserInfo = async () => {
		const usersRef = collection(db, "users");
		const q = query(usersRef, where(documentId(), "==", auth?.currentUser?.email));
		const querySnapshot = await getDocs(q);
		if (querySnapshot.empty) return null;
		const userDoc = querySnapshot.docs[0];
		const user = {email: auth?.currentUser?.email, products: userDoc.data().products};
		return user;
	}

	if (isLoading) {
		return (
			<div className='flex flex-col items-center justify-center w-full h-screen gap-4'>
				<h1 className='text-[1.5rem] font-bold'>Loading...</h1>
			</div>
		);
	}

	return (
		<div className='relative'>
			<BrowserRouter>
				<Routes>
					<Route index element={<Login />} />
					{
						isLogged &&
						<>
							<Route path="/home" element={<Home />} />
							<Route path="/settings" element={<Settings />} />
							<Route path="/product/:id" element={<ProductPage />} />
						</>
					}
					<Route path="*" element={<NotFoundPage />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
