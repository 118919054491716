import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../content/feed-logo.png';
import { Context } from '../Context';

import GoogleImg from '../content/google.jpg';
import TheForkImg from '../content/theFork.jpg';
import BookingImg from '../content/booking.jpg';
import TripadvisorImg from '../content/tripadvisor.jpg';
import InstagramImg from '../content/instagram.jpg';
import AirbnbImg from '../content/airbnb.jpg';

interface Product {
  brand: string;
  lang: string;
  longUrl: string;
  shortUrl: string;
  orderNumber: number;
  price: number;
  title: string;
  id: string;
}

export default function Home() {
  const navigate = useNavigate();
  const context = useContext(Context);

  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    if (context?.products) {
      const sortedProducts = context.products.sort((a: Product, b: Product) => {
        return a.brand.localeCompare(b.brand);
      });
      setProducts(sortedProducts);
    }
  }, [context?.products]);

  function getImage(productCode: string) {
    if (productCode.toLowerCase().includes('google'))
      return GoogleImg;
    else if (productCode.toLowerCase().includes('thefork'))
      return TheForkImg;
    else if (productCode.toLowerCase().includes('instagram'))
      return InstagramImg;
    else if (productCode.toLowerCase().includes('tripadvisor'))
      return TripadvisorImg;
    else if (productCode.toLowerCase().includes('booking'))
      return BookingImg;
    else if (productCode.toLowerCase().includes('airbnb'))
      return AirbnbImg;
    else
      return Logo;
  }

  if (!products)
    return <div className='w-full h-screen flex justify-center items-center text-[1.5rem]'>Loading...</div>;

  return (
    <div className='w-full px-[8vw] flex flex-col gap-8 pt-10'>
      <div className='flex flex-col items-center justify-between gap-4 tresMeio:flex-row'>
        <button className='bg-[#48b181] duration-300 transition-all hover:bg-[#19542c] px-4 py-2 text-white rounded-[10px] w-full tresMeio:w-auto' onClick={() => navigate('/settings')}>Account</button>
        <button className='bg-[#48b181] duration-300 transition-all hover:bg-[#19542c] px-4 py-2 text-white rounded-[10px] w-full tresMeio:w-auto' onClick={() => window.open('/ULTIMATE GUIDE- REVIEW DISC FEEDBECKO.pdf', '_blank')}>User Manual</button>
      </div>
      <div className="flex justify-center w-full">
        <div className='w-[15rem]'>
          <img src={Logo} alt="Logo" id="logo" />
        </div>
      </div>
      <div id="stats-section" className='flex flex-col items-center w-full'>
        <h2 className='w-full text-center'>Impact Overview</h2>
        <div className="flex flex-col justify-around w-full gap-4 oito:gap-8 oito:flex-row">
          <div className="items-center justify-center w-full stat-card">
            <h3 className='font-bold'>Total Discs</h3>
            <p id="total-products" className='text-[#48b181]'>{products?.length || '0'}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 pb-[5rem]">
        <div className="title-with-button">
          <h2 className='font-bold'>Disc Manager</h2>
          <button className="bg-[#48b181] duration-300 transition-all hover:bg-[#19542c] px-4 py-2 text-white rounded-[10px]" onClick={() => window.open('https://feedbecko.com', '_blank')}>Add product +</button>
        </div>
        {
          products && products.length > 0 ? products.map((product: any, index: number) => (
            <div key={index} className='bg-[#e0e0e0] duration-300 transition-all hover:border-black border-[1px] border-[transparent] cursor-pointer w-full px-4 seis:px-8 py-4 rounded-[10px] flex items-center' onClick={() => navigate(`/product/${product?.id}`)}>
              <img src={getImage(product?.title)} alt={product?.productName} className="object-cover w-16 h-16 rounded-full" />
              <div className="flex flex-col flex-grow ml-4">
                <p className='font-bold'>{product?.title}</p>
                <p className=''>{product?.lang || "EN"}</p>
              </div>
            </div>
          )) : <p className='w-full text-center'>No products added</p>
        }
      </div>
      <script src="script.js"></script>
      <div id="add-product-modal" className="modal">
        <div className="modal-content">
          <span className="close-button">&times;</span>
          <h2>Add Product</h2>
          <input type="text" id="product-id-modal" placeholder="Enter Product ID" />
        </div>
      </div>
    </div>
  );
}



